
/* a{
    font-size: 18px;
} */

.ant-form-item-control-input-content{
    display: flex;
    align-items: center;
    justify-content: center;
    
}


.ant-form-item{
        flex-direction: column;
            display: flex;
            margin-bottom: 10px;
}

.ant-form-item-label{
    text-align: left;
}

.ant-form-item-label > label{
    font-size: 17px;
}

.ant-input{
    line-height: 2;
    font-size: 16px;
}

.my-facebook-button-class{
    display: flex;
    grid-column-gap: 10px;
    align-items: center;
}

.ant-input-prefix{
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.ant-row-bottom{
    align-items: center;
}

/* .ant-menu-horizontal>.ant-menu-item>a {
    font-size: 18px;
} */

.layout-home-login-btn {
    border: 2px solid #f3722c;
    color: #f3722c;
    display: inline-flex;
        align-items: center;
        padding: 17px 20px;
}

.layout-home-login-btn:hover {
    background-color: #f3722c;
    border: 2px solid #f3722c;
    color: #fff;
}
.layout-home-signup-btn{
    background-color: #f3722c;
    color: #ffffff;
    display: inline-flex;
    align-items: center;
    border-color: #f3722c;
    padding: 17px 20px;
}

.layout-home-signup-btn:hover{
    background-color: #f3722c;
        color: #ffffff;
        border-color: #f3722c;
}


.login-form-button {
    font-size: 1.2em;
    padding: 20px 65px;
    display: flex;
    align-items: center;
    background-color: var(--orangeColor);
    color: #ffffff;
    width: 100%;
    /* border-radius: 8px; */
    justify-content: center;

}

.login-form-button:hover {
    background-color: #f3722c;
    color: #ffffff;
}

.password-form-button {
    font-size: 1.2em;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* background-color: var(--orangeColor); */
    border: 2px solid var(--orangeColor);
    color: var(--orangeColor)
}

.password-form-button:hover {
    background-color: var(--orangeColor);
    color: #ffffff;
    border: 2px solid var(--orangeColor);
}