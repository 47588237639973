/* image */
.logo-section-changeUserNav{
font-size: 40px;
padding-left: 15px;
align-items: center;
}

.anticon svg{
  font-size: 17px;
  margin-top: 2px;
  /* margin-left: 10px; */
}
.ant-alert.ant-alert-closable {
  padding-right: 70px;
}

/* dashboard */
.dashboard-main-wrapper {
  margin: 0px;
  padding: 0px;
}

.user-dashboard-layout-left {
  flex: 0 0 100%;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.308);
  padding: 2em 1em 1em 1em;
  background-color: rgba(245, 237, 237, 0.527);
}

.user-dashboard-layout-right {
  /* padding: 1em 1em 0 1em; */
  background-color: rgba(241, 225, 224, 0.267);
}

.user-dashboard-main-section {
  /* box-shadow: -2px -2px 19px -7px rgba(41, 41, 41, 0.2); */
  /* background-color: rgb(255, 255, 255); */
  min-height: 100vh;
  /* padding: 0.5em 4.5em; */
  /* margin: 0.7em; */
      margin: 2em 0.5em 0 0.5em;
}

.dashboard-main-menu .ant-menu-sub {
  background: none;
}

.user-nav-right-end-container{
  margin-right: 10px;
}
.spelling-main-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

/* .ant-col-md-24{
  display: flex;
  justify-content: end;
} */



/* Medium devices (landscape tablets, 992px and down) */
@media only screen and (max-width: 992px) {
  
  
  .user-nav-right-end-container{
      display: flex;    
      justify-content: end;
      width: 100%;      
  }
  
  .user-dashboard-layout-left {
    display: none;
  }

  .user-dashboard-main-section {
    margin: 0.5em 0.5em 0 0.5em;
    padding: 1.5em;
    /* margin: 0.7em; */
  }


  /* navigation for mobile and tablets */
  .dashboard-main-menu {
    display: none;
  }

  .sidepanel-antd-menu {
    font-size: 18px;
  }
  .sidepanel-antd-menu .ant-menu-sub {
    background: none;
  }

  .user-dashboard-layout-left {
    flex: 0 0 auto;
  }

  /* maile change gareko */
    .user-header-layout-up{
      display: none;
      /* width: 100%; */
    }
    .about-company-dropdown{
      display: none;
    }

    /* .spelling-check-container h1{
      font-size: 19px;
    } */

    .logo-section-changeUserNav .logo-desktop{
      display: none;
    }

    .user-dashboard-main-section .ant-btn-lg{
      height: auto;
      padding: 4px 9px;
      /* font-size: 12px; */
      border-radius: 2px;
     
    }

    .user-dashboard-main-section .font-conversion-textarea{
      font-size: 17px;
    }

    .typeinenepali-spin-style{
      font-size: 5px;

    }

    .spell-checker-buttons{
      /* display: flex;
      flex-direction: column; */
      font-size: 2px;
      /* width: 100%; */
    }

    .about-company-nav-container,
    .survey-nav-container{
      display: none;
    }

    .user-nav-right-container{
      display: inline-block;
    }
    .spelling-main-container{
      display: flex;
      flex-direction: column;
    }
    .spelling-alert{
      text-align: left;
    }
   

 
    
    
} 

@media only screen and (max-width: 1370px) {
  .ant-layout-header {
      padding: 0px 2px;
      /* white-space: wrap; */
    }

  .ant-menu-horizontal{
    white-space: wrap;
  }

  
}

@media only screen and (max-width: 1110px) {
  .ant-menu-item {
    padding: 0 3px;
  }
    .user-nav-right-profile-container,
    .survey-nav-container {
      margin: 0.1em;
    }
        .responsive-hide-survey{
          font-size: 0;
        }

        .survey-nav-container .survey-btn{
          padding: 0.5em;
        }

        .anticon .anticon-form{
          display: flex;
          align-items: center;
        }

        
}

@media only screen and (max-width: 1240px){
.ant-menu-item{
  padding: 0 10px;
}


}
/* end-of-media-query */

/* .spell-checker-buttons{
  display: flex;
  flex-direction: row;
} */

.typeinenepali-spin-style{
  font-size: 16px;

}

.user-nav-right-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.user-nav-right-end-container{
  display: flex;
}


/* usernav-header-menu */
.usernav-header-menu .ant-menu .ant-menu-item-selected{
  background-color: rgb(240, 206, 12);
}

.usernav-header-menu .ant-btn{
  /* border: 2px solid rgb(243, 227, 2); */
  padding: 0.5em 1em 0.5em 1em;
  height: auto;
  font-size: 1em;
  background-color: rgb(255, 255, 224);
}

.usernav-header-menu .ant-btn:hover{
  background-color: rgb(243, 227, 2);

}


/* about-company-nav-container */
.about-company-nav-container ,
.user-nav-right-profile-container,
.survey-nav-container {
  margin: 0.4em;
}

/* user nav styles */

.usernav-container{
background-color: #fdfffc;
/* margin-top: 10px; */
box-shadow: -2px -2px 19px -7px rgba(41, 41, 41, 0.3);
}

.user-nav-right-profile-container .ant-btn-group .ant-btn {
  font-size: 1.2em;
  height: auto;
  padding: 0.5em 1.2em 0.5em 1.2em;
  text-transform: capitalize;
  /* background-color: rgb(243, 227, 2); */
  background-color: var(--orangeColor);
  color: #fff;
  border: none;
}


.user-nav-right-profile-container .ant-btn-group .ant-btn:hover {
  font-size: 1.2em;
  text-transform: capitalize;
  /* background-color: rgb(243, 227, 2); */
  background-color: var(--orangeColor);
  color: #fff;
  border: none;
}

.user-nav-right-profile-container .ant-btn-group .ant-btn.ant-dropdown-trigger{
background-color: var(--blueColor);
}


.profile-dropdown-btn {
  font-size: 1.2em;
  height: auto;
  padding:0.5em;
  padding-right: 8px;
  padding-left: 8px;

  /* width: 50px;
  margin-left: 5px; */

  /* background-color: rgb(243, 58, 2); */
  background-color: var(--blueColor);
  color: #fff;
  border: none;
}


.profile-dropdown-btn:hover {
  font-size: 1.2em;
  background-color: var(--blueColor);
  color: #fff;

  border: none;
}


.user-nav-right-profile-container .ant-menu {
  background: none;
}


.user-nav-right-profile-container .ant-menu-horizontal {
  border-bottom: none;
}


.survey-btn ,
.company-dropdown-btn{
  /* background-color: rgb(243, 227, 2); */
  background-color: var(--orangeColor);
  color: #fff;
  font-size: 1.2em;
  height: auto;
  padding: 0.5em 1.2em 0.5em 1.2em;
}

.company-dropdown-btn:hover{
  /* background-color: var(--orangeColor); */
  background-color: transparent;
  color: var(--orangeColor);
  /* border: 1px solid var(--orangeColor); */

}

.survey-btn:hover{
  background-color: #fb8500;
  border: 1px solid var(--orangeColor);
  color: #fff;
}


/* main-navigation left side of dashboard */
.dashboard-main-menu {
  margin-top: 2em;
}
.dashboard-main-menu .ant-menu {
  border-right: none;
}

.dashboard-main-menu .ant-menu .ant-menu-item {
  font-size: 1.2em;
  background-color: rgba(216, 216, 216, 0.685);
  border-radius: 4px;
}

.dashboard-main-menu .ant-menu .ant-menu-item:hover {
  background-color: rgb(216, 216, 216);
  color: #000;
}

.dashboard-main-menu .ant-menu .ant-menu-item-selected {
  background-color: rgb(240, 206, 12);
  color: rgb(41, 41, 41);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.568);
  border-right: none;
}

.dashboard-main-menu .ant-menu .ant-menu-item-selected:hover {
  background-color:   rgb(240, 206, 12);
}

.dashboard-main-menu .ant-menu .ant-menu-item-selected:after {
  border: none;
}

/* usernav-header-menu */

.usernav-header-menu .ant-menu .ant-menu-item-selected {
  background-color: rgb(240, 206, 12);
  color: none;
}


.usernav-header-menu .ant-btn:hover {
  color: white;
  /* outline-color: #000; */
}




/* user menu dropdown */

.about-company-dropdown .ant-menu ,
.user-menu-dropdown .ant-menu {
  width: 200px;
}

.about-company-dropdown .ant-menu .ant-menu-item ,
.user-menu-dropdown .ant-menu .ant-menu-item {
  font-size: 1.2em;
  /* background-color: rgba(216, 216, 216, 0.685); */
  border-radius: 4px;

  margin-bottom: 0px;
  margin-top: 0px;
}

.about-company-dropdown .ant-menu .ant-menu-item:hover , 
.user-menu-dropdown .ant-menu .ant-menu-item:hover {
  /* background-color: rgb(240, 206, 12); */
  background-color: #bbd7ff;
  color: #0d6efd;
  transition: all 0.5s;
}

.about-company-dropdown .ant-menu .ant-menu-item a:hover , 
.user-menu-dropdown .ant-menu .ant-menu-item a:hover {
  /* background-color: rgb(240, 206, 12); */
  background-color: #bbd7ff;
  color: #0d6efd;
  transition: all 0.5s;
}

.about-company-dropdown .ant-menu .ant-menu-item .ant-menu-item-selected,
.user-menu-dropdown .ant-menu .ant-menu-item .ant-menu-item-selected {
  /* color: rgb(240, 206, 12); */
  color: #0d6efd;
}

.about-company-dropdown .ant-menu .ant-menu-item-selected a,
.user-menu-dropdown .ant-menu .ant-menu-item-selected a {
  /* color: rgb(240, 206, 12); */
  color:#0d6efd;
}




.company-dropdown-btn{
  border: none;
}



/* .ant-menu-item-selected > a{
  background-color: rgb(240, 206, 12);
} */

.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu:hover,
.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-submenu-active,
.ant-menu-horizontal>.ant-menu-item-open,
.ant-menu-horizontal>.ant-menu-submenu-open,
.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-horizontal>.ant-menu-submenu-selected{
  border-bottom:2px solid rgb(240, 206, 12) ;
  background: blanchedalmond;
}

.ant-dropdown-trigger>.anticon.anticon-down{
  font-size: 12px;
  padding-left: 10px;
}


.survey:hover {
  background-color: #f3f1ee;
  /* border: 1px solid var(--orangeColor); */
  color: #fff;
  padding: 2px;
  cursor: pointer;
}

.profile:hover {
  background-color: #f3f1ee;
  /* border: 1px solid var(--orangeColor); */
  color: #fff;
  padding: 10px;
  cursor: pointer;
}