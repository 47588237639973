

.antd-layout-custom{
    background-color: #fff;
}

.tabpane-home-custom{

}
.tabpane-home-custom .ant-tabs-tab,
.tabpane-home-custom .ant-tabs-tab:hover{
    /* background-color: rgb(23, 84, 119); */
    background-color: rgba(235, 122, 52);
    border-radius: 10px;
    border:none;
    color: #fff;
}

.tabpane-home-custom .ant-tabs-tab-active, 
.tabpane-home-custom .ant-tabs-tab-active:hover{
    background-color: rgb(248, 248, 248);
    border-radius: 10px 0px 0px 10px;
    /* border:none; */
    /* border: 1px solid rgba(235, 122, 52); */
    color: rgba(235, 122, 52);
    /* color: rgb(27, 27, 27); */

}
.tabpane-home-custom .ant-tabs-tabpane-active:focus{
    outline:none;

}
.tabpane-home-custom .ant-tabs-ink-bar.ant-tabs-ink-bar-animated{
    /* background-color: #747474; */
    background-color: rgb(248, 248, 248);
    height:4px;
    width:4px;
    display: none;
}
/* 
.tabpane-home-custom .ant-tabs-ink-bar,
.tabpane-home-custom .ant-tabs-ink-bar-animated{
    background-color: #747474;
    background-color: #747474;
    height:4px;
    width:4px;
    display: hidden;
} */

.tabpane-home-custom .ant-tabs-bar,
.tabpane-home-custom .ant-tabs-left-bar,
.tabpane-home-custom .ant-tabs-large-bar{
    border-right:none;
    margin-right:10px;
}
.tabpane-home-custom .ant-tabs-content{
    padding:30px;
    background-color: rgb(255, 255, 255);
    border:2px solid rgb(196, 195, 195);
    border-radius: 10px;
    margin:20px;
}

.tabpane-home-custom .ant-tabs-nav-container {
    margin-top:30px;
    margin-right:20px;
    border:none

}



  .font-conversion-textarea.ant-input{
    font-size: 1.6em;
  }

  .ant-btn-primary{
    background-color: #1d67f1;
    color: rgb(255, 255, 255);
    border-color: #1d67f1 ;
  }
  .ant-btn-primary:hover{
    background-color: #1059e0;
    color: rgb(255, 255, 255);
    border-color: #1d67f1 ;
  }
  .ant-btn-primary:active{
    background-color: #1059e0;
    color: rgb(255, 255, 255);
    border-color: #1d67f1 ;
  }

  .ant-btn-primary:focus{
    background-color: #1059e0;
    color: rgb(255, 255, 255);
    border-color: #1d67f1 ;
  }
  .btn-yellow {
    background-color: #fadb14;
    color: #000;
    border-color: #fadb14 ;
  }
  .btn-yellow:hover {
    background-color: #fadb14;
    color: #000;
    border-color: #fadb14 ;
  }
  .btn-yellow:active {
    background-color: #fadb14;
    color: #000;
    border-color: #fadb14 ;
  }
  .btn-yellow:focus {
    background-color: #fadb14;
    color: #000;
    border-color: #fadb14 ;
  }


  .btn-orange{
    background-color: #fa8c16;
    color: #fff;
    border-color: #fa8c16;
  }

  .btn-orange:hover{
    background-color: rgba(235, 122, 52);
    color: #fff;
    border-color: rgba(235, 122, 52);
  }
  .btn-orange:focus{
    background-color: #fa8c16;
    color: #fff;
    border-color: #fa8c16;
  }
  .btn-orange:active{
    background-color: #fa8c16;
    color: #fff;
    border-color: #fa8c16;
  }

  .btn-orange-border{
    font-size: 1.2em;
      padding: 20px 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    color: #f3722c;
    background-color: #fff;
    border-color: #f3722c;
  }
  .btn-orange-border:hover{
    background-color: #f3722c;
    color: #fff;
    border-color: #f3722c;
  }
    .btn-orange-border:focus {
      background-color: #f3722c;
      color: #fff;
      border-color: #f3722c;
    }
  
    .btn-orange-border:active {
      background-color: #f3722c;
      color: #fff;
      border-color: #f3722c;
    }