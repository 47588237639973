.dictionarySearchArea .ant-input-group{
    width: 50%;
}

.dictionarySearchArea.ant-input-group-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}
.dictionarySearchArea{
    padding: 7px 10px 7px 10px;
}

ant-input-search dictionarySearchArea ant-input-search-enter-button ant-input-search-medium ant-input-affix-wrapper

/* .ant-input-affix-wrapper{
    line-height: 2.5715;
} */

.dictionarySearchArea .ant-input-lg{
    font-size: 20px;
}

.dictionarySearchArea .ant-btn-lg{
    height:auto;
    font-size:20px; 
}


.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
}

.dictionarySearchArea .ant-input-group {
    width: 50%;
}

@media (max-width: 600px) {
    .dictionarySearchArea .ant-input-group {
        width: 80%;
    }
}

.search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.responsive-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

@media (max-width: 600px) {
    .responsive-search {
        flex-direction: column;
    }

    .responsive-search .ant-btn {
        margin-top: 10px;
        width: 50%;
    }
}

.autocomplete-input{
    width: "600px"
}

@media (max-width: 600px) {
    .responsive-search {
        flex-direction: column;
    }

    .autocomplete-input {
        /* margin-top: 10px; */
        width: 50%;
    }
}

.custom-dropdown .ant-select-item {
    font-size: 18px;
    /* Increase font size */
    padding: 10px 16px;
    /* Increase padding */
}
