* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

@font-face {
  font-family: "preeti";
  src: url(../fonts/preeti.TTF);
}

@font-face {
  font-family: "kantipur";
  src: url(../fonts/kantipur.ttf);
}

@font-face {
  font-family: "aakriti";
  src: url(../fonts/aakriti.TTF);
}

@font-face {
  font-family: "aalekh";
  src: url(../fonts/aalekh.TTF);
}

@font-face {
  font-family: "ganess";
  src: url(../fonts/ganess.TTF);
}

@font-face {
  font-family: "himalb";
  src: url(../fonts/himalb.TTF);
}

@font-face {
  font-family: "navjeevan";
  src: url(../fonts/navjeevan.TTF);
}

@font-face {
  font-family: "PCSnepali";
  src: url(../fonts/PCSNepali.TTF);
}

@font-face {
  font-family: "shangrila";
  src: url(../fonts/shangrila.TTF);
}

@font-face {
  font-family: "shreenath";
  src: url(../fonts/shreenath.TTF);
}

@font-face {
  font-family: "sumodacharrya";
  src: url(../fonts/sumodacharya.TTF);
}

:root {
  --orangeColor: #ff6d18;
  --blueColor: #0077b6;
  /* --orangeBorderColor: #ff7b00; */
  --orangeButtonBgColor: #FFD580;
  --orangeDropdownColor: #FFD580;
  --toastify-color-light: #ffafaf;

}



body {
  margin: 0;
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nepali-text {
  font-family: "Noto Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  /* font-family: 'Martel', serif; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0px;
}

.logo {
  width: 120px;
  height: 31px;
  background: rgb(247, 244, 244);
  margin: 16px 24px 16px 0;
  float: left;
}

.logo-section {
  font-size: 40px;
}

.main-wrapper {
  margin: 0px auto;
  width: 100%;
  background: linear-gradient(19.9deg, rgba(243,
        185,
        148) 8.1%, rgba(255, 255, 255) 47.9%);
}

.sub-main-wrapper {
  margin-top: 100px;
}

.nav-main {
  color: #fff;

  /* background-color: rgb(255, 201, 52); */
  border-bottom: 1px solid #cccccc88;
  padding-bottom: 5px;
  background: white;
}

.nav-main .ant-menu-horizontal {
  border-bottom: none;
}

/* .container {
  margin-top: 10px;
  margin-bottom: 40px;
} */

.container-heading {
  margin: 80px 0px;
}

.bg-blue-yellow-gradient {
  /* background-color: rgb(240, 240, 240); */
  background: rgb(238, 225, 174);
  /* background: linear-gradient(
    89.9deg,
    rgba(208, 246, 255, 1) 0.1%,
    rgba(255, 237, 237, 1) 47.9%,
    rgba(255, 255, 231, 1) 100.2%
  ); */
  background: linear-gradient(10.9deg, rgba(243, 185, 148) 0.1%, rgba(255, 255, 255) 47.9%);
  padding: 1em 0;
  /* height: 84vh; */
  /* min-height: 80vh; */
}

.wrapper-gray {
  /* background-color: rgb(240, 240, 240); */
  background: rgb(238, 225, 174);
  background: linear-gradient(89.9deg,
      rgba(208, 246, 255, 1) 0.1%,
      rgba(255, 237, 237, 1) 47.9%,
      rgba(255, 255, 231, 1) 100.2%);
  padding: 0.5em 0;
}

.main-banner {
  /* background-color: rgb(240, 240, 240); */
  background: rgb(238, 225, 174);
  background: radial-gradient(circle,
      rgba(238, 225, 174, 0.8662815467984069) 0%,
      rgba(127, 178, 237, 0.7794468129048494) 100%);
  padding: 1em 0;
}

.bg-test {
  background-image: linear-gradient(89.9deg,
      rgba(208, 246, 255, 1) 0.1%,
      rgba(255, 237, 237, 1) 47.9%,
      rgba(255, 255, 231, 1) 100.2%);
}



.color-orange {
  color: #ff6d18;
}

.my-facebook-button-class {
  padding: 12px;
  border: none;
  border-radius: 4px;
  margin: 5px 0;
  opacity: 0.85;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  background-color: #3b5998;
  color: white;
  cursor: pointer;
}

.my-facebook-button-class:hover {
  opacity: 1;
}

.my-facebook-button-class:active {
  position: relative;
  top: 1px;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 20px 40px; */
  max-width: 400px;
  /* width: 500px; */
  min-width: 320px;
  margin: auto;
  /* margin-top: 50px; */
  /* border: 2px solid rgba(82, 82, 82, 0.466); */
  /* border-radius: 20px; */
  background-color: #fff;
  /* background-color: rgba(245, 245, 245, 0.699); */
  -webkit-box-shadow: -2px 0px 19px -10px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: -2px 0px 19px -10px rgba(0, 0, 0, 0.65);
  /* box-shadow: -2px 0px 19px -10px rgba(0, 0, 0, 0.65); */
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 20px 40px; */
  
}

.contact-form-container {
  /* display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center; */
  padding: 40px;
  /* margin-top: 50px; */
  /* border: 2px solid rgba(83, 83, 81, 0.623); */
  border-radius: 20px;
  background-color: rgba(240, 239, 237, 0.699);
  -webkit-box-shadow: -2px 0px 19px -10px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: -2px 0px 19px -10px rgba(0, 0, 0, 0.65);
  box-shadow: -2px 0px 19px -10px rgba(0, 0, 0, 0.65);
}

.logo-desktop {
  height: 50px;
}

.user-main-wrapper {
  background-color: rgb(241, 242, 248);
}

.nav-wrapper {}

.logo-section {
  padding: 5px 0px 0px 15px;
}

.border-image-landing {
  border: 2px solid rgb(187, 187, 187);
  border-radius: 5px;
}

.card-box-shadow-default {
  -webkit-box-shadow: 5px 8px 14px 5px rgba(221, 228, 248, 0.6);
  -moz-box-shadow: 5px 8px 14px 5px rgba(221, 228, 248, 0.6);
  box-shadow: 5px 8px 14px 5px rgba(221, 228, 248, 0.6);
  border-radius: 15px;
  margin: 10px;
}



/* card styles */

.card-with-shadow {
  border-radius: 15px;
  margin: 10px;
  box-shadow: 5px 8px 14px 5px rgba(221, 228, 248, 0.6);
}


/* 
  Responsive Layout codes
  Screen smaller than 
  */

.sidepanel {
  display: none;
}

.openbtn {
  display: none;
}

/* google signin */
.googleSigninButton {
  width: 100%;
  /* box-shadow: none !important; */
  /* background-color: #f8f9fa !important; */
  display: flex !important;
  justify-content: space-around;
  cursor: pointer;
  /* transition: #dee2e6 .1s linear, #dee2e6 .1s linear, color .1s linear; */
}

.googleSigninButton:hover {
  background-color: #e9ecef !important;
}

.googleLoginContainer {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  /* border: 1px solid #e9ecef; */
}

.googleLoginContainer:hover {
  background-color: #f8f9fa;
  padding: 10px;
  /* box-shadow: 5px 8px 14px 5px rgba(221, 228, 248, 0.6); */
  border: 1px solid rgb(187, 187, 187);

}

/* phone number sign in */
.phoneLoginButton {
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  height: 48px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  background-color: #f8f9fa !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  border-radius: 2px;
  border: 1px solid transparent
}



.phoneLoginButton:hover {
  background-color: #e9ecef !important;
  color:rgba(0, 0, 0, 0.54) ;
  border: 1px solid transparent}


/* container left side type one */

.container-layout-left-one {
  /* height: 80vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 70%;
  margin-top: 20px;
}

.login-phone-number-container {
  /* height: 80vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
}

.container-layout-right-one {
  height: 80vh;
  display: flex;
  justify-content: center;
}

.justify-text {
  text-align: justify;
}

.home-banner-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 0px 0px 40px;

}

.layoutcontact-p-style p {
  color: #000000;
  line-height: 70%;
}

/* Medium devices (landscape tablets, 992px and down) */
@media only screen and (max-width: 992px) {

  /* navigation for mobile and tablets */

  .nav-main {
    display: none;
  }

  /* The sidepanel menu */
  .sidepanel {
    display: block;
    height: 100%;
    /* Specify a height */
    width: 0;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Stay on top */
    top: 0;
    left: 0;
    background-color: rgb(250, 250, 230);
    /* Black*/
    overflow-x: hidden;
    /* Disable horizontal scroll */
    padding-top: 60px;
    /* Place content 60px from the top */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidepanel */
  }

  /* The sidepanel links */
  .sidepanel a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 16px;
    color: #1d1d1d;
    display: block;
    transition: 0.3s;

  }

  /* When you mouse over the navigation links, change their color */
  .sidepanel a:hover {
    color: #f3550c;
  }

  .sidepanel .button {
    margin: 10px 10px 10px 10px;
    border-radius: 50px;

  }

  .sidepanel .button-orange {
    background-color: rgb(250, 86, 10);
  }

  /* Position and style the close button (top right corner) */
  .sidepanel .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  /* Style the button that is used to open the sidepanel */
  .openbtn {
    display: inline;
    width: 100%;
    font-size: 20px;
    cursor: pointer;
    /* background-color: rgb(255, 201, 52); */
    background-color: var(--orangeColor);
    color: white;
    padding: 10px 15px;
    border: none;
  }

  .openbtn:hover {
    /* background-color: rgb(243, 190, 43); */
    background-color: var(--orangeColor);
  }

  .tabpane-home-custom {
    text-align: left;
  }

  .tabpane-home-custom .ant-tabs-bar {
    float: none;
    margin-bottom: 20px;
    padding-left: 25%;
    padding-right: 25%;


  }

  .tabpane-home-custom .ant-tabs-bar .ant-tabs-tab {
    text-align: center;
  }

  .container-layout-left-one {
    /* height: 30vh; */
    padding: 10px 0px;
  }

  .container-layout-right-one {
    height: 80vh;
  }

  .home-our-softwares-card {
    margin-bottom: 20px;
  }

  .home-banner-left {
    padding: 20px;
  }
}


/* flexbox styles  */

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-justify-flex-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.flex-row-justify-space-between {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  align-items: baseline;
}

@media only screen and (max-width: 992px) {

  .flex-row-justify-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    gap: 12px;

  }

}


.flex-row-justify-space-between-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
}

.flex-row-justify-space-between-3 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  align-items: center;
}


.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-justify-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


/* some important custom styles added later */
.reader-archive-bg {
  background-image: url('../images/books_bg_2.jpg');
}


/* RegisterButton */

.register-button{
 width: 400px;
 background-color: rgb(62, 216, 62);
}

.register-button:hover{
  background-color: rgb(4, 194, 4);
}

.login-button {
  width: 400px;
  background-color: #ff6d18;
  margin-top: 20px;
}

.login-button:hover {
  background-color: #ff8844;
}


.otp-field a {
  color: #003893;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.8;
  font-style: italic;
  border-bottom: 1px solid #003893;
  text-align: center;
}

.otp-field{
  width: 350px;
}

.login .number,
.register .number {
  width: 300px;
}

.ant-btn .phoneLoginButton .ant-btn-lg img{
  font-size: 50px;
}

.custom-card {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  /* or a height that suits your layout */
}

.custom-tabs {
  width: 100%;
  max-width: 400px;
  /* Adjust the width as needed */
}

.custom-tab-pane {
  width: 100%;
  /* margin-left: 20px ; */
}

.ant-tabs-tab {
  width: 160px;
  /* Adjust the width as needed */
  text-align: center;
  font-weight: bold;
}

.resend-pin {
  font-weight: bold;
  color: #1c65e4;
}

.resend-pin:hover{
  cursor: pointer;
}

.custom-tabs .ant-tabs-nav-scroll{
  margin-left: 30px;
}

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.custom-toast {
  background-color: #f5f5f5 !important;
  font-size: 15px;
  /* Light background */
  color: #333 !important;
  /* Dark text color */
  border-radius: 10px !important;
  /* Rounded corners */
  padding: 20px !important;
  /* Padding */
  border: 1px solid #ccc !important;
  /* Border */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
  /* Box shadow */
  font-family: 'Arial', sans-serif !important;
  /* Custom font */
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 2px solid rgba(200, 200, 202, 0.65);
}

/* @media (min-width: 1200px) {
  .container {
    max-width: 1100px;
  }
} */