
.height-100{
    height: 100%;
}

.user-font-conversion__container{
    height: 80vh;
    width: 100%;
    border-radius: 8px;
    /* box-shadow: 0 1px 4px 0 rgba(0,0,0,0.37);; */

}
.user-font-conversion__left-section{
    border-right: 2px solid #ccc;
}

.user-font-conversion__top{
    height: 10%;
    background: rgb(236, 236, 236);
    padding-left: 10px;
    padding-right: 10px;
}

.user-font-conversion__top__convert-button{
    position: absolute;
    left: 89.5%;
    z-index: 99;
    background-color: none;
    border-radius: 200px;

    border: 3px solid #ccc;

}
.user-font-conversion__top__convert-icon{
    font-size: 1.6em;
}
.user-font-conversion__body{
    height:75%;
    padding: 8px;
}

.user-font-conversion__body .font-conversion-textarea{
    resize: none;
    font-size: 20px;
}

.user-font-conversion__bottom{
    padding: 0px 10px 0px 10px;
    height: 8%;
    background: rgb(238, 236, 236);
}


@media only screen and (max-width: 992px) {
    .user-font-conversion__container{
        height: auto;
    }
    .user-font-conversion__top{
        height: 60px;
    }
    .user-font-conversion__bottom{
        padding: 0px 10px 0px 10px;
        height: 40px;
        background: rgb(238, 236, 236);
    }
    .user-font-conversion__top__convert-button{
        position: absolute;
        left: 60%;
        z-index: 0;
        background-color: none;
        border-radius: 200px;
        border: 3px solid #ccc;

        size: .7em;
        /* font-size: 10px; */

    }

    .user-font-conversion__body{
        height: 300px;
        padding: 8px;
    }

    .user-font-conversion__bottom .ant-btn{
    font-size: 12px;
    }
    .ant-btn-round.ant-btn-lg{
        font-size: 12px;
    }


    
    
    
    
}