.link-archive-articles-container{
    margin: 50px;
    width: 100%;

    box-shadow: -1px 0px 8px 0px rgba(73, 50, 20, 0.63);
    padding: 10px;
    border-radius: 10px;

    background-color: #fff;


}



/* article card styles */


.article-card-landing {
    background-color: rgb(241, 241, 241);
    margin-bottom: 10px;
    text-align: left;

}

.article-card-landing .ant-card-head-title{
    font-size: 1.3em;
}
.article-card-landing .ant-card-body{
    padding: 12px;
}

.article-card-landing__article-link{
    color: rgb(29, 29, 29);
}
.article-card-landing__article-link:hover{
    color: rgb(29, 29, 29);
}
.article-card-landing__image {
  width: 100%;
  height: auto;
  padding: 5px;
  /* padding: 0px 5px 0px 0px; */

  border: 1px solid rgba(0, 0, 0, 0.178);
  border-radius: 5px;
  background-color: aliceblue;
}

.article-card-landing__description {

    font-size: 1em;
    padding: 0px 0px 0px 5px;
    width: 100%;
    text-align: justify;

    

}

.article-card-landing-footer{
    cursor: context-menu;

    margin-top: 20px;
    background-color: rgb(255, 255, 253);
    padding: 5px;
    font-style: italic;
    font-size: .9em;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.article-card-landing-footer .article-card-landing-footer__left{
    margin-top:7px;
    margin-left:10px;
}

.article-card-landing-footer .bold{
    font-weight: bold;

}
.article-card-landing-footer__right__button-two{
    margin-left: 10px;
}

.article-card-landing-footer__tags-container{
    margin-top: 5px;
}