
#transliteration{
    width: 100%;
    height: 500px;
    padding: 10px;
    font-size: 1.2em;
}

.type-in-nepali-container{
    outline: none;
    border: none;
}

.type-in-nepali-container .ant-tabs-content .ant-tabs-tabpane-active:focus{
    outline: none;
    border: none;
}

.type-in-nepali-ttf-container:focus{

}

/* .hijje-check-btn-style{
    margin-right: 10px;
    background-color: var(--blueColor);
    color: white;
} */
.hijje-check-btn-style,
.continue-type-btn-style{
    margin-right: 8px;
    border: 2px solid var(--orangeColor);
    /* background-color: rgb(255, 255, 224); */
    /* background-color: var(--orangeColor); */
    color: var(--orangeColor);
  
}



.hijje-check-btn-style:hover,
.continue-type-btn-style:hover{
    background-color: var(--orangeColor);
    /* outline-color: rgb(243, 227, 2); */
    color: white;
    border: 2px solid var(--orangeColor);
}

/* new-styling for clear button */
.ant-btn-dangerous{
    background-color: #ff4d4f;
    color: white;
}

.ant-btn-dangerous:hover{
    background-color: rgb(243, 58, 2);
    color: white;
}

/* type-in-nepali-styling */
.typeInNepali-heading-btn-style{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    
}

.typeInNepali-heading-style{
    text-align: center;
    flex: 1;
    margin-left: 100px;
}

.typeInNepali-btn-style{
    display: flex;
    align-items: flex-end;
}

.spellingCheck-btn-style{
    display: flex;
    align-items: center;
}

.spellingCheck-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;    
    align-items: baseline;
}

/* footer-style */
.footer-container{
    display: flex;
    justify-content: center;
}
.footer-sub-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
}
.footer-sub-container p{
    margin-bottom: 0.4em;
}

.footer-content{
    margin-right: 10px;

}
.anticon svg{

font-size: 13px;
}

@media only screen and (max-width: 447px){
.typeInNepali-btn-style{

}

}

@media only screen and (max-width: 992px) {
    .typeInNepali-heading-btn-style{
        display: flex;
        flex-direction: column;
       
    }

    .typeInNepali-heading-style {
        margin-left: 0;

    }
    .typeInNepali-btn-style {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-top: 5px;
    }

    .spellingCheck-btn-style{
        display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            margin-top: 5px;
            flex-wrap: wrap;
    }

    .footer-sub-container{
        width: 100%;
    }

    



    
}

@media only screen and (max-width: 448px) {


.continue-type-btn-style{
    margin-bottom: 7px;
    /* font-size: 5px; */
    font-size: 12px;
   
    
}

.spelling-check-copy-btn-style{
    font-size: 12px
}
    
}

@media only screen and (max-width: 280px) {
.spellingCheck-btn-style {
        display: flex;
        flex-direction: column;
        
     
    }
}
